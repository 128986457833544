@tailwind base;
@tailwind components;
@tailwind utilities;

/* global styles go here */
body,
html,
#__next {
  @apply h-full scroll-smooth;
  overscroll-behavior: none;
}

#__next > footer {
  top: 100vh;
  position: sticky;
}

/* Text Styles */
h1,
h2,
h3 {
  @apply font-secondary font-bold text-gray-charcoal;
}

h4,
.eyebrow {
  @apply font-primary text-m1 font-bold lg:text-m2;
}

h1 {
  @apply text-xl1 lg:text-xl2;
}

h2 {
  @apply text-l2 lg:text-xl1;
}

h3 {
  @apply text-m2 lg:text-l1;
}

.eyebrow {
  @apply uppercase;
}

p {
  @apply pb-4 font-primary leading-[26px] text-gray-charcoal last:pb-0;
}

.rich-text-ul,
.rich-text-ol,
.event-page-content ul,
.event-page-content ol {
  font-family: theme("fontFamily.primary");
  padding-bottom: theme("spacing.4");

  @apply last:pb-0;
}

.rich-text-ul,
.event-page-content ul {
  list-style-type: disc;
}

.rich-text-ol,
.event-page-content ol {
  list-style-type: decimal;
}

.rich-text-ul li,
.rich-text-ol li,
.event-page-content ul li,
.event-page-content ol li {
  margin-left: theme("spacing.4");
}

.rich-text-ul li::marker,
.rich-text-ol li::marker,
.event-page-content ul li::marker,
.event-page-content ol li::marker {
  font-family: theme("fontFamily.primary");
}

.rich-text-ul li p,
.rich-text-ol li p {
  @apply py-0 text-left leading-[26px];
}

/* End Text Styles */

/* Call to Action */
.primary-link,
.secondary-link,
.secondary-white-link,
.ghost-link,
.download-link,
.email-link,
.linkedin-link {
  @apply font-primary uppercase text-primary-1 transition-all duration-200;
}
.primary-link,
.ghost-link {
  @apply w-full rounded-sm border-2 border-tertiary-1 px-5 py-2.5 text-center sm:w-max;
}

.primary-link {
  @apply bg-tertiary-1 hover:border-secondary-1 hover:bg-secondary-1 hover:text-white;
}

.secondary-white-link {
  @apply font-primary uppercase text-white transition-all duration-200;
}

.secondary-link,
.secondary-white-link {
  @apply underline decoration-tertiary-1 decoration-2 underline-offset-4 hover:decoration-secondary-1;
}

.ghost-link {
  @apply hover:bg-tertiary-1;
}

.inline-link {
  @apply font-primary underline hover:no-underline;
}

.phone-link,
.chat-link,
.email-link,
.linkedin-link {
  @apply flex w-max items-center gap-2 font-primary text-base font-medium text-white underline decoration-tertiary-1 decoration-2 underline-offset-4 hover:decoration-secondary-1;
}

.download-link,
.email-blue-link {
  @apply flex items-center gap-2 font-primary text-base font-medium text-primary-1 underline decoration-tertiary-1 decoration-2 underline-offset-4 hover:decoration-secondary-1;
}

.phone-link::before,
.chat-link::before,
.download-link::before,
.email-link::before,
.linkedin-link::before,
.email-blue-link::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.phone-link::before {
  background-image: url("public/assets/cta-icons/phone.svg");
  width: 15px;
  height: 22px;
}
.chat-link::before {
  background-image: url("public/assets/cta-icons/chat-bubble.svg");
  width: 15px;
  height: 15px;
}

.download-link::before {
  background-image: url("public/assets/cta-icons/download.svg");
  width: 15px;
  height: 15px;
}

.email-link::before,
.email-blue-link::before {
  background-image: url("public/assets/cta-icons/email.svg");
  height: 12px;
  width: 18px;
}
.linkedin-link::before {
  background-image: url("public/assets/cta-icons/linkedin.svg");
  height: 18px;
  width: 18px;
}

/* End Call to Action */

/* Footer */
.social-icon path {
  fill: white;
  transition-property: all;
  transition-duration: 200ms;
}

.social-icon:hover path {
  fill: theme("colors.secondary.1");
}
/* End Footer */

/* Main Menu */
/* prevent CTA btn from wrapping right at lg breakpoint */
header nav ~ a {
  white-space: nowrap;
}

/* Top Level Menu Items */
.menu > ul > li.top-level {
  color: white;
  font-size: theme("fontSize.l1");
  font-family: theme("fontFamily.secondary");
  white-space: nowrap;
  padding-bottom: 40px;
  position: relative;
  width: 100%;

  @apply last:pb-0 xl:p-4 xl:font-primary xl:text-base xl:uppercase xl:text-primary-1 xl:hover:bg-secondary-2;
}

.menu > ul > li.top-level > .nonlinked,
.menu > ul > li.top-level > a {
  cursor: pointer;
  @apply hover:underline hover:xl:no-underline;
}

.menu > ul:has(.open-second-level) {
  transform: translateX(-100%);

  @apply xl:translate-x-0;
}
.menu > ul > .open-second-level {
  transform: translateX(100%);

  @apply xl:translate-x-0;
}

.menu > ul {
  transition: all;
  transition-duration: 200ms;

  @apply xl:flex;
}

.menu .top-level > .nonlinked {
  display: flex;
  gap: 8px;
  align-items: center;
}

.menu .top-level.open-second-level > .nonlinked {
  gap: 24px;
}

.menu .top-level.open-second-level > .nonlinked::before {
  content: "";
  mask: url("public/assets/right-chevron.svg") no-repeat 50% 50%;
  mask-size: cover;
  -webkit-mask: url("public/assets/right-chevron.svg") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  display: inline-block;
  position: relative;
  width: 10px;
  height: 12px;
  background-color: white;
  transform: rotate(180deg);

  @apply xl:hidden;
}

.menu .top-level > .nonlinked::after {
  content: "";
  mask: url("public/assets/right-chevron.svg") no-repeat 50% 50%;
  mask-size: cover;
  -webkit-mask: url("public/assets/right-chevron.svg") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  display: inline-block;
  position: relative;
  width: 10px;
  height: 12px;
  background-color: white;

  @apply xl:h-2.5 xl:w-3 xl:-translate-y-1 xl:rotate-90 xl:bg-secondary-1;
}

.menu .top-level.open-second-level > a::after,
.menu .top-level.open-second-level > .nonlinked::after {
  display: none;
  @apply xl:block;
}

.menu > ul:has(.open-second-level) > .top-level:not(.open-second-level) {
  display: none;

  @apply xl:block;
}

@screen xl {
  .menu > ul > .top-level:hover > ul {
    position: absolute;
    background-color: theme("colors.secondary.2");
    padding: 25px 17px;
    left: 0;
    top: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    min-width: 150px;
  }
  .menu > ul > .top-level:hover > ul > li.second-level {
    display: block;
  }

  .menu > ul > .open-second-level:hover > ul > li.second-level > a {
    display: block;
  }
  .menu > ul > .top-level > ul > li.second-level > a {
    @apply border-b-2 border-b-tertiary-1 transition-all duration-200 hover:border-b-secondary-1;
  }
}

/* Second Level Menu Items */
.menu > ul > .open-second-level > ul > li.second-level > a {
  display: block;
  color: white;
  font-family: theme("fontFamily.primary");
  font-size: theme("fontSize.base");
  margin-top: theme("spacing.7");
  width: max-content;
  border-bottom-width: 2px;
  text-decoration-line: none;
  margin-left: 32px;

  @apply border-b-primary-1 hover:border-b-tertiary-1 xl:ml-0 xl:mt-0 xl:hidden xl:border-b-tertiary-1 xl:text-primary-1 xl:hover:border-b-secondary-1;
}

.menu ul > .top-level:hover > ul > li.second-level > a {
  text-decoration-line: none;
}

.menu > ul > .top-level:not(.open-second-level) > ul > .second-level {
  transform: translateX(100%);
  display: none;

  @apply xl:translate-x-0;
}

/* Hamburger button */
.hamburger-btn rect {
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: all 0.3s ease-in-out;
}

.hamburger-btn svg.open .top-line {
  transform: rotate(45deg);
  transform-origin: center top;
  x: 12px;
  y: 12px;
  fill: white;
}

.hamburger-btn svg.open .middle-line {
  opacity: 0;
}

.hamburger-btn svg.open .bottom-line {
  transform: rotate(-45deg);
  transform-origin: center top;
  x: -12px;
  y: 12px;
  fill: white;
}
/* End Hamburger button */
/* End Main Menu */

/* Search Bar */
.searchbar::before {
  content: "";
  width: 11px;
  height: 11px;
  background-image: url("public/assets/magnifying-glass.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.searchbar input {
  width: 100%;
  font-family: theme(fontFamily.primary);
  padding-left: 1rem;
  font-size: theme("fontSize.base");
  background-color: transparent;
  border-bottom: 0.5px solid theme("colors.secondary.1");
}

.dark-searchbar-input {
  color: theme("colors.primary.1");
}

.light-searchbar-input {
  color: white;
}

input::placeholder {
  font-family: theme(fontFamily.primary);
  font-size: theme("fontSize.base");
}

.dark-searchbar-input::placeholder {
  color: theme("colors.primary.1");
}

.light-searchbar-input::placeholder {
  color: white;
}
/* End Search Bar */

/* Breadcrumbs */
.crumb::after {
  content: url("public/assets/bc-arrow.svg");
  position: absolute;
  right: 0.5rem;
}
/* End Breadcrumbs */

/* Image and Content */
.image-and-content-section:has(.nested-image-and-content-components) .image-and-content-image {
  margin-bottom: 0px;
}

.image-and-content-section:has(.nested-image-and-content-components) .image-and-content {
  border-bottom: 0px;
}

.event-archive {
  @apply flex-col-reverse lg:flex-row-reverse;
}

.event-archive .image-and-content-image {
  @apply mb-0;
}

.event-archive .image-and-content {
  @apply border-b-0;
}

/* End Image and Content */

/* Divider */
.page-content > .content-divider {
  @apply px-6;
}

.video-hero + .content-divider:has(+ .logo-carousel-section) {
  @apply -mb-2 lg:hidden;
}

.content-divider + .image-and-content-section {
  @apply mt-14 lg:mt-20;
}

/* End Divider */

/* CTA Banner */
#__next:has(.cta-banner-section),
.page-content:has(.cta-banner-section) {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cta-banner-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}
/* End CTA Banner */

/* Dynamic Video */
.play-video:hover .circle {
  fill: theme("colors.secondary.2");
}
.play-video:hover .triangle {
  fill: theme("colors.secondary.1");
}
/* End Dynamic Video */

/* Search Bar */
.searchbar::before,
.searchbar-dark::before {
  content: "";
  width: 26px;
  height: 26px;
  background-image: url("public/assets/magnifying-glass.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 9px;
  top: 50%;
  transform: translateY(-54%);
  z-index: 50;
}

.searchbar input[type="text"],
.searchbar-dark input[type="text"] {
  width: 100%;
  font-family: theme(fontFamily.secondary);
  /* border-radius: theme(borderRadius.3); */
  font-size: theme("fontSize.base");
  line-height: theme("fontSize.base");
  padding: theme("spacing[2.5]") 0 theme("spacing[2.5]") 2.5rem;
}

.searchbar input {
  font-family: theme(fontFamily.secondary);
  font-size: theme("fontSize.base");
  line-height: theme("fontSize.base");
}

#search-query,
.fake-searchbar {
  view-transition-name: search-field;
}

.search-results mark {
  @apply bg-secondary-1 px-1 py-0;
}

/* Begin Search Page */
.pagefind-ui__form {
  @apply relative;
}

.pagefind-ui__search-input {
  @apply relative block w-full rounded-sm border-2 py-2 pl-9 pr-2 font-secondary transition-all placeholder:italic;
}

.pagefind-ui__form::before {
  content: "";
  background: url("public/assets/magnifying-glass.svg") no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  width: 26px;
  height: 20px;
  @apply left-2.5 top-[10px] z-20;
}

.pagefind-ui__message {
  @apply my-10 pb-0 font-primary text-l2 text-primary-1;
}

.pagefind-ui__search-clear {
  position: absolute;
  right: 1rem;
  top: 0.6rem;
}

.pagefind-ui__result-link {
  @apply font-primary text-l2 font-medium text-primary-1 hover:underline;
}

.pagefind-ui__result-excerpt {
  @apply mb-10 mt-0 text-base text-gray-charcoal;
}

.pagefind-ui mark {
  @apply break-words rounded-md bg-white font-primary text-tertiary-1;
}

.pagefind-ui__button {
  @apply primary-link;
}

.loading-indicator {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 3px;
  background:
    conic-gradient(from 135deg at top, #cb9731 90deg, #0000 0) 0 calc(50% - 2px) / 8.5px 4.25px,
    radial-gradient(farthest-side at bottom left, #0000 calc(100% - 3px), #cb9731 calc(100% - 2.5px) 99%, #0000) top
      right/50% 50% content-box content-box,
    radial-gradient(farthest-side at top, #0000 calc(100% - 3px), #cb9731 calc(100% - 2.5px) 99%, #0000) bottom / 100%
      50% content-box content-box;
  background-repeat: no-repeat;
  animation: s1 1s infinite linear;
}

@keyframes s1 {
  100% {
    transform: rotate(1turn);
  }
}

@screen lg {
  .searchbar-dark input {
    background-color: transparent;
    border: 1px solid white;
    color: white;
  }
}
/* End Search Bar */

/* Timeline */

.timeline {
  background: var(--Purple-Gradient, linear-gradient(101deg, #c5c5e0 2.9%, #e0e0eb 96.01%));
}

/* End Timeline */
/* FAQ Section */
.page-content > .faq-section {
  @apply px-6;
}

.accordion-button .plus,
.accordion-button circle {
  @apply transition-all duration-300;
}

.accordion-button circle {
  fill: theme("colors.secondary.2");
}

.accordion-button:hover circle {
  fill: theme("colors.tertiary.1");
}

.accordion-button .expanded .plus {
  opacity: 0;
}
/* End FAQ Section */

/* Three Column Section */
.three-column-section {
  @apply max-w-screen-lg;
}

.three-column-section:has(.resource-card) {
  @apply max-w-screen-xl;
}
/* End Three Column Section */

.archive-tab-bar::-webkit-scrollbar {
  display: none;
}

/* Timeline Section */

.timeline-list {
  counter-reset: timeline-counter;
  list-style: none;
}

.timeline-list li {
  counter-increment: timeline-counter;
  position: relative;
}

.timeline-content::before {
  @apply static font-secondary text-secondary-1 group-odd:left-0 group-even:right-0 lg:absolute lg:-top-9;
  content: attr(year);
  font-size: 2rem;
  font-weight: bold;
  --size: 44px;
  line-height: var(--size);
  padding: 0 20px;
  max-width: max-content;
  height: var(--size);
  z-index: 20;
  background: white;
  text-align: center;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 1rem 0;
  font-variant-numeric: lining-nums;
}

/* End Timeline Section */

/* Salesforce Emebd Section */

.salesforce-form input {
  height: 40px;
}
